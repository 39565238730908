import { Layout, Footer } from 'components';
import {
  HeaderSection,
  LearnMoreSection,
  ApplyReasonsSection,
  AgroBenefitsSection,
  RequirementsSection,
  StepsSection,
  TestimonialsSection,
} from 'page-components/program';
import { MenuItemProps, MenuItemTypeEnum } from 'components/Menu';
import { UnavailableModalContent } from 'components/UnavailableModalContent';
import { fbTrack } from 'utils';
import { color } from 'style';
import metaImage from 'assets/images/agro-meta.png';
import headerImage from 'assets/images/agro-header.svg';
import logoImage from 'assets/images/Agro-logo.svg';

const PROJECT_NAME = 'Women in Agriculture';
const APPLY_LINK = 'https://form.typeform.com/to/k4khsBxy';
const INFO_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_in_Agriculture.pdf';
const FAQ_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_in_Agriculture-FAQs.pdf';

const onApplyFBTrack = () => fbTrack('track', 'Lead', { content_name: 'AGRO' });

const menuItems: MenuItemProps[] = [
  { label: 'Συχνές Ερωτήσεις', type: MenuItemTypeEnum.LINK, href: FAQ_PDF },
  {
    href: APPLY_LINK,
    unavailable: UnavailableModalContent.isUnavailable(),
    unavailableContent: <UnavailableModalContent projectName={PROJECT_NAME} />,
    type: MenuItemTypeEnum.APPLY_BUTTON,
    onApply: onApplyFBTrack,
  },
];

const applyReasons = [
  'Ασχολείστε ή θέλετε να ασχοληθείτε επαγγελματικά με τον αγροδιατροφικό τομέα ή/και τον αγροτουρισμό.',
  'Θέλετε να αποκτήσετε γνώσεις και τεχνογνωσία που θα σας βοηθήσουν να εξελίξετε τη δραστηριότητά σας.',
  'Επιθυμείτε να εμβαθύνετε τις γνώσεις σας μέσα από ένα διαδραστικό πρόγραμμα που σας παρέχει προσωποποιημένη καθοδήγηση.',
  'Σας αρέσουν οι νέες τεχνολογίες και σας ενδιαφέρει να εντάξετε την καινοτομία στον κλάδο σας.',
  'Θέλετε να αναπτύξετε οριζόντιες δεξιότητες που παίζουν σημαντικό ρόλο στη δημιουργία και διατήρηση σχέσεων με συναδέλφους και συνεργάτες.',
  'Σας ενδιαφέρει να λάβετε πιστοποίηση για τις δεξιότητες και τις γνώσεις που αναπτύξατε.',
];

const requirements = [
  'Άνω των 18 ετών',
  'Από όλη την Ελλάδα',
  'Επιχειρηματική δραστηριότητα στον αγροδιατροφικό τομέα ή/και τον αγροτουρισμό.',
];

const secondStep = {
  title: (
    <span>
      Διεξαγωγή επιμόρφωσης, βιωματικής μάθησης και mentoring
      <br />
      <i>(01/04/2023 - 30/06/2024)</i>
    </span>
  ),
  list: [
    '2-4 ώρες επιμόρφωσης ανά εβδομάδα',
    '4 ώρες εργαστηρίων ανάπτυξης δεξιοτήτων',
    '20 - 40 λεπτά mentoring ανά εβδομάδα',
  ],
};

const testimonials = [
  {
    name: 'Νάγια Π., παραγωγός εσπεριδοειδών, Νομός Αργολίδας',
    text: 'H εμπειρία αυτή ήταν για εμένα, κάτι σαν το λούνα παρκ για τα παιδιά, όπου η κάθε επόμενη ενότητα έκρυβε και κάτι πιο ενδιαφέρον από την προηγούμενη και ήρθε ακριβώς την στιγμή που το χρειαζόμουν.',
  },
  {
    name: 'Κατερίνα Σ., παραγωγός αμυγδαλιών, Νομός Κοζάνης',
    text: 'Βρήκα το πρόγραμμα εξαιρετικά ενδιαφέρον και προς όφελος μας από πολλές απόψεις, καθώς θεωρώ ότι είμαστε νέας γενιάς επιχειρηματίες που ασχολούνται με τον πρωτογενή τομέα και οι νέες τεχνολογίες θα μας βοηθήσουν να εξελιχθούμε και να βελτιώσουμε την ποιότητα της δουλειάς μας.',
  },
  {
    name: 'Iro M., ελαιοπαραγωγός, Νομός Σερρών',
    text: 'Εκτιμώ πολύ που οι ομιλητές των σεμιναρίων φαίνεται να αγαπάνε αυτό που κάνουν και το μεταδίδουν σε εμάς. Είναι πολύ όμορφο να λαμβάνεις γνώσεις από κάποιον που έχει αγάπη και πάθος για αυτό που μελετά.',
  },
];

const Page = () => (
  <Layout
    logo={logoImage}
    meta={{
      title: PROJECT_NAME,
      description: 'Δωρεάν πρόγραμμα για τη στήριξη της γυναίκας αγρότισσας.',
      image: metaImage,
    }}
    menuItems={menuItems}
  >
    <HeaderSection
      title={
        <div>
          Δραστηριοποιείστε ή θέλετε να δραστηριοποιηθείτε στον αγροτικό τομέα; Το δωρεάν πρόγραμμα επιμόρφωσης Women in
          Agriculture δημιουργήθηκε για εσάς!
        </div>
      }
      image={headerImage}
      imageWidth={440}
      imageAlt="Αγρότισσα"
      applyLink={APPLY_LINK}
      disableApply={UnavailableModalContent.isUnavailable()}
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      onApply={onApplyFBTrack}
    />
    <LearnMoreSection
      title="Ανακαλύψτε περισσότερα για το Women in Agriculture"
      text={
        <span>
          Το Women in Agriculture, μια δράση στο πλαίσιο του <b>Προγράμματος EQUALL της Τράπεζας Πειραιώς</b> σε
          συνεργασία με τη{' '}
          <b>
            100mentors, την Αμερικάνικη Γεωργική Σχολή και το Alba Graduate Business School, The American College of
            Greece
          </b>
          , απευθύνεται σε όλες τις γυναίκες που διατηρούν επιχείρηση ή σκοπεύουν να ξεκινήσουν δική τους επιχείρηση
          στον κλάδο της αγροδιατροφής ή/και του αγροτουρισμού, στους επόμενους 6 μήνες.
          <br />
          <br />
          Το συγκεκριμένο πρόγραμμα έχει σχεδιαστεί ώστε να προσφέρει ένα ευρύ φάσμα γνώσεων και καλών πρακτικών{' '}
          <b>σε θέματα εκσυγχρονισμού της γεωργίας, επιχειρηματικότητας και πράσινης γεωργίας</b>. Το πρόγραμμα,
          <b>τρίμηνης διάρκειας</b>, είναι εμπλουτισμένο με τομεακή εκπαίδευση που εστιάζει σε συγκεκριμένους κλάδους
          ή/και προϊόντα, ανάλογα με τα ενδιαφέροντα των συμμετεχουσών.
          <br />
          <br />Η ιδιαιτερότητα του Women in Agriculture - και αυτό που το καθιστά μοναδικό στο είδος του - είναι ότι{' '}
          <b>
            συνδυάζει τη θεωρητική επιμόρφωση με τη βιωματική μάθηση και το mentoring από καθηγητές και επαγγελματίες
          </b>
          , έτσι ώστε κάθε συμμετέχουσα να λαμβάνει εξατομικευμένη καθοδήγηση και υποστήριξη για να μπορεί να ξεκινήσει
          μια δική της επιχείρηση ή να εξελίξει την ήδη υπάρχουσα επιχειρηματική της δραστηριότητα!
          <br />
          <br />
          {(!UnavailableModalContent.isUnavailable() || UnavailableModalContent.isBeforeUnavailable()) &&
            'Οι δηλώσεις συμμετοχής για τον 4ο κύκλο ξεκινούν στις 9 Φεβρουαρίου 2024 και θα γίνονται δεκτές μέχρι και τις 25 Μαρτίου 2024.'}
          {UnavailableModalContent.isAfterUnavailable() &&
            'Οι δηλώσεις συμμετοχής για τον 4ο κύκλο ολοκληρώθηκαν στις 25 Μαρτίου 2024.'}
        </span>
      }
    />
    <ApplyReasonsSection
      backgroundColor={color.hmPurple1}
      heading="Γιατί να δηλώσετε συμμετοχή στο πρόγραμμα Women in Agriculture"
      bullets={applyReasons}
    />
    <AgroBenefitsSection infoLink={INFO_PDF} />
    <RequirementsSection
      backgroundColor={color.hmPurple1}
      title="Προϋποθέσεις συμμετοχής"
      subtitle="Στο Women in Agriculture μπορούν να συμμετέχουν γυναίκες:"
      labels={requirements}
    />
    <StepsSection
      title="Ποια είναι τα στάδια του Προγράμματος;"
      projectName={PROJECT_NAME}
      faqLink={FAQ_PDF}
      secondStep={secondStep}
      applyLink={APPLY_LINK}
      disableApply={UnavailableModalContent.isUnavailable()}
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      onApply={onApplyFBTrack}
    />
    <TestimonialsSection
      backgroundColor={color.hmPurple1}
      title="Έχουν πει για το Women in Agriculture…"
      testimonials={testimonials}
    />
    <Footer
      signatures={[
        { title: 'Στρατηγικοί Συνεργάτες', logos: ['piraeus', '100mentors'] },
        { title: 'Συντελεστής Εκπαίδευσης', logos: ['afst', 'deree'] },
      ]}
    />
  </Layout>
);

export default Page;
